<template>
    <div class="sportimg">
        <div>
            <img :src="data.image1" alt="">
        </div>
        <div>
            <img :src="data.image2" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            data:window.setData('百姓健身房-无法进入-右下角两张图片', {
                image1:require('../../../assets/images/sport1.png'),
                image2:require('../../../assets/images/sport2.png'),
            }),
        }
    }
}
</script>

<style scoped>
.sportimg{
    width:5.94rem;
    height:6.40rem;
    position: relative;
    display: flex;
    justify-content: space-between;
}
.sportimg>div{
    width:2.76rem;
    height:1.86rem;
}
.sportimg>div>img{
    width:100%;
    height:100%;
}
</style>

